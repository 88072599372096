import { template as template_f33b8fb4cccb4bc88e7e0f357164f956 } from "@ember/template-compiler";
const WelcomeHeader = template_f33b8fb4cccb4bc88e7e0f357164f956(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
