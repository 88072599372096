import { template as template_db968768c17147d78786c18bf3e4cd42 } from "@ember/template-compiler";
const FKControlMenuContainer = template_db968768c17147d78786c18bf3e4cd42(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
