import { template as template_26b33961a90846078d83dde97fa07821 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_26b33961a90846078d83dde97fa07821(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
